import { Box, Avatar, Typography } from '@mui/material'
import { useNostrEvents } from 'nostr-react'
import React, { useCallback, useEffect } from 'react'
import { sortBy } from 'sort-by-typescript'
import { ProfileData } from '../../app/types'
import { parseContent, getUsername } from '../../lib/utils'
import DisplayName from '../DisplayName/DisplayName'

type Props = {
    pubkey: string
    size: 'large' | 'small'
    // userProfile: ProfileData
}

const UserCardHorizontal: React.FC<Props> = ({ pubkey, size }) => {
    const variant = size === 'large' ? 'h6' : 'body1'

    const { events: users, onEvent } = useNostrEvents({
        filter: {
            authors: [pubkey],
            kinds: [0],
            since: 0,
            limit: 1,
        },
    })

    const listen = useCallback(() => {
        onEvent((event) => {
            // console.log('User Card: Event:', event.content)
        })
    }, [onEvent])

    useEffect(() => {
        listen()
    }, [listen])

    return (
        <>
            {users.sort(sortBy('-created_at')).map((user, i) => {
                const userProfile: ProfileData | undefined = parseContent(
                    user.content
                )
                if (!userProfile || i > 0) return null

                return (
                    <Box
                        key={`user-${i}`}
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                        alignItems='center'
                        sx={{
                            p: '1rem',
                            // border: '1px solid lightgray',
                            borderRadius: '.5rem',
                            // boxShadow: '1',
                            // width: '150px',
                        }}
                    >
                        <Box sx={{ pr: '1rem' }}>
                            <Avatar
                                src={userProfile?.picture}
                                sx={{
                                    width: size === 'large' ? '5rem' : '3rem',
                                    height: size === 'large' ? '5rem' : '3rem',
                                }}
                            />
                        </Box>
                        <Box display='flex' flexDirection='column'>
                            <Typography
                                component='div'
                                variant={variant}
                                fontWeight='fontWeightRegular'
                            >
                                {/* {userProfile?.display_name} */}
                                <DisplayName profileData={userProfile} />
                            </Typography>
                            {/* <Typography component='div' variant='body2'>
                                {!!userProfile && getUsername(userProfile)}
                            </Typography> */}
                        </Box>
                    </Box>
                )
            })}
        </>
    )
}

export default React.memo(UserCardHorizontal)
