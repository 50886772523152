import { Avatar, Box, Button } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { parseContent } from '../../lib/utils'
import { nip19 } from 'nostr-tools'
import ProfileCardDisplayName from '../DisplayName/ProfileCardDisplayName'
import QrCodeIcon from '@mui/icons-material/QrCode'
import QRCode from 'react-qr-code'
import { useAuth } from '../../app/useAuth'

type Props = {
    userContent: string
    pubkey: string
}

const ProfileCard: React.FC<Props> = ({ userContent, pubkey }) => {
    const { isSignedIn, principalPubkey } = useAuth()
    const [showQR, setShowQR] = useState(false)
    // console.log('Profile Card: User Content:', userContent)

    const userProfile = useMemo(() => {
        if (!!userContent) {
            const profileData = parseContent(userContent)
            if (!!profileData) {
                profileData.pubkey = pubkey
                profileData.npub = nip19.npubEncode(pubkey)
            }
            return profileData
        }
        return null
    }, [userContent])

    const toggleQR = () => {
        setShowQR(!showQR)
    }

    const isPrincipalSameAsTarget = () => {
        return isSignedIn() ? pubkey === principalPubkey : false
    }

    return (
        <Box key={`user-${userProfile?.pubkey}`}>
            <Box
                display='flex'
                flexDirection='column'
                gap={1}
                sx={{
                    mt: isPrincipalSameAsTarget() ? '.25rem' : '1.5rem',
                    textAlign: 'center',
                }}
            >
                <Button
                    onClick={() => toggleQR()}
                    sx={{ display: 'flex', gap: '.25rem' }}
                >
                    <QrCodeIcon />
                    {showQR ? <>Hide</> : <>Show</>} QR Code
                </Button>
                <Box sx={{ width: '11rem', height: '11rem' }}>
                    {showQR ? (
                        <Box
                            sx={{
                                p: '1rem',
                                width: '100%',
                                height: 'auto',
                                // border: '1px solid lightgray',
                                // borderRadius: '.25rem',
                            }}
                        >
                            <QRCode
                                size={256}
                                style={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: '100%',
                                }}
                                value={`https://notabot.net/profile/${userProfile?.pubkey}`}
                                viewBox={`0 0 256 256`}
                            />
                        </Box>
                    ) : (
                        <Avatar
                            src={userProfile?.picture}
                            sx={{ width: 'inherit', height: 'inherit' }}
                        />
                    )}
                </Box>
                {!!userProfile && (
                    <ProfileCardDisplayName profileData={userProfile} />
                )}
            </Box>
        </Box>
    )
}

export default React.memo(ProfileCard)
