import axios from '../app/axios-config'
import { RescissionPayload } from '../screens/Profile/PrincipalVerificationStatus'
import { VerificationNotice } from '../screens/VerifyUser/VerifyUser'
import { VerifyUserPayload } from '../screens/VerifyUser/VerifyUser'

export const getSignWithApp = () => {
    // console.log('Endpoints: Sign With App...')
    return axios.get(`/signWithApp`)
}

export const postSignWithApp = (payload: VerifyUserPayload) => {
    // console.log('Endpoints: Sign With App...')
    return axios.post(`/signWithApp`, payload)
}

export const signVerificationNotice = (payload: VerificationNotice) => {
    return axios.post(`/signVerificationNotice`, payload)
}

export const signRescission = (payload: RescissionPayload) => {
    return axios.post(`/signRescission`, payload)
}
