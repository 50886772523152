import { Box, Typography, Divider } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import { getPublicKey } from 'nostr-tools'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../app/useAuth'
import SignInForm from './SignInForm'
import useSignInValidation from './useSignInValidation'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { checkValidityOfPrivateKey, MalformedKeyError } from '../../lib/utils'
import AppLink from '../../components/FormControls/AppLink'
import AboutKeyManagement from '../../components/Content/KeyManagement/AboutKeyManagement'
import Toggler from '../../components/Toggler/Toggler'

export type SignInFields = {
    hasWindowDotNostr: boolean
    privateKey: string
}

const SignIn = () => {
    const { isSignedIn, signin, hasWindowDotNostr } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const targetUrl = !!location?.state?.targetUrl
        ? location.state.targetUrl
        : ''
    const { validationSchema } = useSignInValidation()
    const initialValues: SignInFields = {
        hasWindowDotNostr: false,
        privateKey: '',
    }
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)

    const handleActivateValidation = () => {
        setValidateOnChange(true)
    }

    const handleSubmit = async (
        fields: SignInFields,
        { setFieldError }: FormikHelpers<SignInFields>
    ) => {
        console.log('Sign In: Form submitted...')
        let publicHex = ''
        let privateHex: string | null = null

        if (hasWindowDotNostr) {
            // @ts-ignore: Property nostr does not exist
            publicHex = await window.nostr.getPublicKey()
            console.log('Sign In: Signing in w/ ext...')
        } else {
            try {
                privateHex = checkValidityOfPrivateKey(fields.privateKey)
                publicHex = getPublicKey(privateHex)
            } catch (e) {
                if (e instanceof MalformedKeyError) {
                    console.error('Create Badge Form:', e)
                    setFieldError(
                        'privateKey',
                        'This is not a valid Private Key.'
                    )
                }
            }
        }
        console.log('Sign In: Public Hex:', publicHex)
        if (publicHex) signin(publicHex, privateHex, targetUrl)
    }

    // const hasWindowDotNostr = useMemo(() => {
    //     // @ts-ignore: Property nostr does not exist
    //     return window.nostr !== undefined
    //     // @ts-ignore: Property nostr does not exist
    // }, [])

    return (
        <Box sx={{ pt: '1rem', px: '1rem' }}>
            <Box sx={{ pt: '1rem' }}>
                <Typography variant='h5' fontWeight='fontWeightRegular'>
                    Welcome, Nostriches!
                </Typography>
                <Typography
                    variant='h6'
                    fontWeight='fontWeightRegular'
                    id='authenticity-anonymity-autonomy'
                    sx={{ pt: '1rem' }}
                >
                    NotaBot seeks to eliminate spam while respecting Anonymity.
                </Typography>
                {/* <p>
                    <Typography component='span' variant='body1'>
                        Join the humans in the fight against spam by verifying
                        your friends aren't spam bots.
                    </Typography>
                </p> */}
                <p>
                    <Typography component='span' variant='body1'>
                        The Purpose: Join a network of fully verified NotABots.
                        All humans. No spam bots. <br />
                        (Relay incoming)
                    </Typography>
                </p>
                <p>
                    <Typography component='span' variant='body1'>
                        The Objective: To become a Fully Verified NotABot.
                    </Typography>
                </p>
                <p>
                    <Typography component='span' variant='body1'>
                        The Way:
                    </Typography>
                </p>
                <p>
                    <Typography component='span' variant='body1'>
                        1) Receive 5 verifications <br />
                        2) Give 5 verifications <br />
                        3) Make sure they're all reciprocal <br />
                    </Typography>
                </p>
                <p>
                    <Typography component='span' variant='body1'>
                        You can only give one verification for each verification
                        you receive, so verifications must be earned and
                        thoughtfully given. Getting fully verified requires
                        social coordination and a bit of patience. You can do
                        it.
                    </Typography>
                </p>
                <p>
                    <Typography component='span' variant='body1'>
                        The Strategy: GLHF
                    </Typography>
                </p>
                <p>
                    <Typography component='span' variant='body1'>
                        Reputation matters. Confidence scores will be a thing.
                        Don't be the one to invite the bots. Your verifications
                        can be rescinded.
                    </Typography>
                </p>
            </Box>

            <Box sx={{ pt: '1rem', pb: '.5rem', textAlign: 'center' }}>
                <AppLink to='/about'>
                    <Typography
                        component='span'
                        color='primary'
                        sx={{
                            fontSize: '1.25rem',
                        }}
                    >
                        Learn More About NotABot
                    </Typography>{' '}
                    <ChevronRightIcon
                        color='primary'
                        style={{ fontSize: '1.25rem' }}
                    />
                </AppLink>
            </Box>

            {!isSignedIn() && (
                <Box sx={{ pb: '1rem' }}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        sx={{ mt: '2rem', pb: '1rem' }}
                    >
                        <Divider sx={{ width: '100%' }}>
                            Sign In To Get Started
                        </Divider>
                    </Box>

                    <Box sx={{ pt: '0rem' }}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={validateOnChange}
                            validateOnBlur={validateOnChange}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                /* and other goodies */
                            }) => {
                                return (
                                    <SignInForm
                                        onActivateValidation={
                                            handleActivateValidation
                                        }
                                    />
                                )
                            }}
                        </Formik>
                    </Box>
                </Box>
            )}

            {!isSignedIn() && !hasWindowDotNostr && (
                // <Toggler title='How are private keys handled?' open={true}>
                <AboutKeyManagement />
                // </Toggler>
            )}
        </Box>
    )
}

export default SignIn
