import React, { useMemo } from 'react'
import { Avatar } from '@mui/material'
import { parseContent } from '../../lib/utils'

type Props = {
    userContent: string
}

const MenuProfileCard: React.FC<Props> = ({ userContent }) => {
    const userProfile = useMemo(() => {
        if (!!userContent) {
            return parseContent(userContent)
        }
        return null
    }, [userContent])
    return (
        <Avatar
            src={userProfile?.picture || ''}
            sx={{
                mt: '0',
                pt: 0,
                width: '2.5rem',
                height: '2.5rem',
                // border: '1px solid gray',
            }}
        />
    )
}

export default React.memo(MenuProfileCard)
