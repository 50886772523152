import { Box, Avatar, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { ProfileData } from '../../app/types'
import { parseContent } from '../../lib/utils'
import DisplayName from '../DisplayName/DisplayName'
import SyncIcon from '@mui/icons-material/Sync'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'
import appKeys from '../../app/appKeys'

type Props = {
    profileContent: string
    pubkey: string
    isReciprocal: boolean
}

const UserCardVertical: React.FC<Props> = ({
    profileContent,
    pubkey,
    isReciprocal,
}) => {
    const width = '7rem'
    const profileData = parseContent(profileContent) as ProfileData

    return (
        <Box key={`user-card-v-${pubkey}`} sx={{ textAlign: 'center' }}>
            <NavLink
                to={`/profile/${pubkey}`}
                style={{ textDecoration: 'none' }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        pb: '.5rem',
                        // border: '1px solid lightgray',
                        borderRadius: '.5rem',
                        boxShadow: '1',
                        maxWidth: { width },
                    }}
                >
                    {/* <Box
                        display='flex'
                        justifyContent='flex-end'
                        sx={{
                            position: 'absolute',
                            zIndex: 100,
                            width: '7rem',
                            height: '9rem',
                            // border: '1px solid black',
                        }}
                    >
                        <SyncIcon
                            sx={{
                                // position: 'absolute',
                                // width: 'inherit',
                                // height: 'inherit',
                                // pt: '-50px',
                                // zIndex: '100',
                                width: '2rem',
                                height: '2rem',
                                backgroundColor: '#fff',
                                border: '2px solid rebeccapurple',
                                borderRadius: '50px',
                            }}
                        />
                    </Box> */}
                    <Avatar
                        variant='square'
                        src={profileData?.picture}
                        sx={{
                            width: { width },
                            height: 'auto',
                            borderRadius: '.5rem .5rem 0 0',
                        }}
                    />
                    <Box
                        sx={{
                            m: '.5rem .5rem 0 .5rem',
                            width: '90px',
                            height: '25px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipses',
                            border: '0px solid gray',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            // component='div'
                            variant='body2'
                            fontWeight='fontWeightRegular'
                            color='text.primary'
                            sx={{ textDecoration: 'none' }}
                        >
                            <DisplayName profileData={profileData} />
                        </Typography>
                    </Box>
                </Box>
            </NavLink>
            {pubkey !== appKeys.publicHex && (
                <>
                    {isReciprocal && (
                        <SyncIcon sx={{ mt: '.25rem', color: 'lightgray' }} />
                    )}
                    {!isReciprocal && (
                        <SyncDisabledIcon
                            sx={{ mt: '.25rem', color: 'lightgray' }}
                        />
                    )}
                </>
            )}
        </Box>
    )
}

export default React.memo(UserCardVertical)
