import {
    Box,
    Typography,
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import UserCardHorizontal from '../../components/Cards/UserCardHorizontal'
import usePublish from '../../app/usePublish'
import { useState } from 'react'
import { useAuth } from '../../app/useAuth'
import appKeys from '../../app/appKeys'
import { NostrReactEvent } from '../../app/types'
import { usePrincipalProfile } from '../../app/usePrincipalProfile'
import { postSignWithApp, signVerificationNotice } from '../../app/endpoints'

export type VerifyUserPayload = {
    fromPublicHex: string
    toPublicHex: string
    tagAsProgenitor: boolean
    nestedEvent: string
}

export type VerificationNotice = {
    fromPubkey: string
    toPubkey: string
}

const VerifyUser = () => {
    const {
        hasWindowDotNostr,
        principalPrivateKey,
        principalPubkey,
        isPrincipalAppAccount,
    } = useAuth()
    const {
        targetPubkey,
        principalReceivedList,
        principalGivensList,
        isPrincipalAProgenitor,
    } = usePrincipalProfile()
    const {
        createUnsignedNestedVerificationEvent,
        sign,
        broadcastEvent,
        // postVerification,
        // postNoticeOfVerification,
    } = usePublish()
    const navigate = useNavigate()
    const [shouldNotify, setShouldNotify] = useState(true)
    const [invalidPrivateKeyMsg, setInvalidPrivateKeyMsg] = useState('')
    const fromHex = principalPubkey!
    const toHex = targetPubkey
    const unlockedGives =
        principalReceivedList.length - principalGivensList.length

    // console.log('Verify User: Principal Received:', receivedList.length)
    // console.log('Verify User: Principal Givens:', givensList.length)
    // console.log('Verify User: Principal Unlocked Gives:', unlockedGives)
    // console.log('Verify User: Principal Received:', receivedList)
    // console.log('Verify User: Principal Givens:', givensList)

    // useEffect(() => {
    //     console.log('Verify User: Should Notify:', shouldNotify)
    // }, [shouldNotify])

    const submitVerification = async () => {
        if (!canVerify()) {
            console.debug(
                'Verify User: The principal cannot verify the target.'
            )
            return
        }
        // console.log('Verify User: Submit called...')
        // console.log("Verify User: From's Public Hex:", fromPublicHex)

        let tagAsProgenitor = principalPubkey === appKeys.publicHex

        const unsignedNestedVerificationEvent =
            createUnsignedNestedVerificationEvent(
                principalPubkey!,
                toHex,
                tagAsProgenitor
            )

        // let principalPubkey: string = ''

        if (hasWindowDotNostr) {
            try {
                const signedNestedVerificationEvent =
                    // @ts-ignore: Property nostr does not exist
                    await window.nostr.signEvent(
                        unsignedNestedVerificationEvent
                    )
                // console.log(
                //     'Verify User: Signed V Event:',
                //     signedNestedVerificationEvent
                // )

                const response = await postSignWithApp({
                    fromPublicHex: principalPubkey!,
                    toPublicHex: toHex,
                    tagAsProgenitor: tagAsProgenitor,
                    nestedEvent: JSON.stringify(signedNestedVerificationEvent),
                })
                // console.log('Verify User: Call API: Response:', response.data)

                broadcastEvent(response.data as NostrReactEvent)

                if (shouldNotify) {
                    const signedNoticeResp = await signVerificationNotice({
                        fromPubkey: principalPubkey!,
                        toPubkey: toHex,
                    })
                    console.log(
                        'Verify User: Call API: Signed Notice Response:',
                        signedNoticeResp.data
                    )

                    broadcastEvent(signedNoticeResp.data)
                    // postNoticeOfVerification(principalPubkey!, toHex)
                }
            } catch (e) {
                console.error(e)
                setInvalidPrivateKeyMsg(
                    'This private key is not able to sign this verification.'
                )
                document.documentElement.scrollTo(0, 0)
                return
            }
        } else {
            // Display an error message if the principalPubkey is not the same
            // as the From Hex. That means the principal cannot sign this verification.
            if (
                !principalPubkey ||
                !principalPrivateKey ||
                principalPubkey !== fromHex
            ) {
                setInvalidPrivateKeyMsg(
                    'This private key is not able to sign this verification.'
                )
                document.documentElement.scrollTo(0, 0)
                return
            } else {
                setInvalidPrivateKeyMsg('')

                try {
                    let tagAsProgenitor = principalPubkey === appKeys.publicHex
                    const signedNestedVerificationEvent = sign(
                        unsignedNestedVerificationEvent as NostrReactEvent,
                        principalPrivateKey!
                    )

                    const response = await postSignWithApp({
                        fromPublicHex: principalPubkey!,
                        toPublicHex: toHex,
                        tagAsProgenitor: tagAsProgenitor,
                        nestedEvent: JSON.stringify(
                            signedNestedVerificationEvent
                        ),
                    })
                    console.log(
                        'Verify User: Call API: Response:',
                        response.data
                    )

                    broadcastEvent(response.data as NostrReactEvent)

                    if (shouldNotify) {
                        const signedNoticeResp = await signVerificationNotice({
                            fromPubkey: principalPubkey!,
                            toPubkey: toHex,
                        })
                        console.log(
                            'Verify User: Call API: Signed Notice Response:',
                            signedNoticeResp.data
                        )

                        broadcastEvent(signedNoticeResp.data)
                    }
                } catch (e) {
                    console.error(e)
                }
                console.log('Verify User: Post Verification: Success')
            }
        }

        navigate(`/profile/${toHex}`)
    }

    const isVerificationAReciprocation = () => {
        return (
            principalReceivedList.find(
                (from) => from.pubkey === targetPubkey
            ) != undefined
        )
    }

    const canVerify = () => {
        return (
            isPrincipalAppAccount() ||
            unlockedGives > 0 ||
            isVerificationAReciprocation() ||
            isPrincipalAProgenitor()
        )
    }

    return (
        <Box
            sx={{
                pt: '1.5rem',
                pb: '1rem',
                px: '1rem',
                width: '100%',
                // border: '1px solid gray',
            }}
        >
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-evenly'
                alignItems='center'
            >
                {!!invalidPrivateKeyMsg && (
                    <Typography
                        variant='body2'
                        color='error'
                        sx={{ mt: '1rem' }}
                    >
                        {invalidPrivateKeyMsg}
                    </Typography>
                )}
                <Typography
                    variant='h6'
                    fontWeight='fontWeightRegular'
                    sx={{ py: '1rem' }}
                >
                    You,
                </Typography>
                <UserCardHorizontal pubkey={fromHex} size='large' />
                <Typography
                    variant='h6'
                    fontWeight='fontWeightRegular'
                    sx={{ py: '1rem' }}
                >
                    are verifying that
                </Typography>
                <UserCardHorizontal pubkey={toHex} size='large' />
                <Typography
                    variant='h6'
                    fontWeight='fontWeightRegular'
                    sx={{ py: '1rem' }}
                >
                    is not a bot.
                </Typography>
                {/* Unlocked Gives: {unlockedGives}
                <br />
                Is Reciprocation: {isVerificationAReciprocation().toString()} */}

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name='shouldNotify'
                                value={shouldNotify}
                                defaultChecked={true}
                                onChange={() => {
                                    setShouldNotify(!shouldNotify)
                                }}
                                inputProps={{
                                    'aria-label': 'Checkbox demo',
                                }}
                            />
                        }
                        label='Notify recipient'
                    />
                </FormGroup>

                <Box
                    sx={{
                        py: '1.5rem',
                        display: 'flex',
                        width: '100%',
                        border: '0px solid gray',
                    }}
                >
                    <Button
                        // onClick={() => callAPI()}
                        onClick={() => submitVerification()}
                        // disabled={!canVerify()}
                        variant='contained'
                        color='primary'
                        sx={{ width: '100%' }}
                    >
                        <Typography
                            color='primary.contrastText'
                            fontWeight='fontWeightMedium'
                        >
                            Sign
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default VerifyUser
