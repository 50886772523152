import { Outlet } from 'react-router-dom'
import { PrincipalProfileContextProvider } from '../../app/usePrincipalProfile'
import { TargetProfileContextProvider } from '../../app/useTargetProfile'

const PrincipalProfileWrapper = () => {
    return (
        <PrincipalProfileContextProvider>
            <Outlet />
        </PrincipalProfileContextProvider>
    )
}

export default PrincipalProfileWrapper
