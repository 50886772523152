import React, { createContext, useContext, useMemo } from 'react'
import { useNostrEvents } from 'nostr-react'
import { Event as NostrEvent } from 'nostr-tools'
import { useParams } from 'react-router-dom'
import appKeys from './appKeys'
import releaseDate from './releaseDate'
import { NostrReactEvent, VerificationSummary, VEvent } from './types'
import { useAuth } from './useAuth'

type TargetProfileContextState = {
    targetPubkey: string
    rawVList: NostrReactEvent[]
    vList: VEvent[]
    gList: VEvent[]
    fromList: VerificationSummary[]
    toList: VerificationSummary[]
    givensList: VerificationSummary[]
    reciprocationsList: VerificationSummary[]
    receivedList: VerificationSummary[]
    receivedReciprocationsList: VerificationSummary[]
    hasPrincipalVerifiedTarget: () => boolean
    isTargetAProgenitor: () => boolean
    isTargetThePrincipalUser: () => boolean
}

export const TargetProfileContext = createContext(
    {} as TargetProfileContextState
)

type Props = { children: React.ReactNode }

export const TargetProfileContextProvider: React.FC<Props> = ({ children }) => {
    const { principalPubkey } = useAuth()
    const { pubkey } = useParams()

    if (!pubkey) {
        throw new Error('Target Profile Context: Target Pubkey not found')
    }

    const targetPubkey = useMemo(() => pubkey!, [pubkey])

    const { events: rawVList } = useNostrEvents({
        filter: {
            kinds: [5555, 5556],
            authors: [appKeys.publicHex],
            '#p': [targetPubkey!],
            since: releaseDate,
        },
    })

    const [vList, gList] = useMemo(() => {
        let frList: string[] = []

        const vList = rawVList.reduce((acc, event) => {
            // If the event is a recission,
            // then add the verifier to the frList
            // and exit without updating the acc
            if ((event as VEvent).kind === 5556) {
                frList = [...frList, event.tags[0][1]]
                return acc
            }

            // If the frList doesn't include the current event's verifier
            if (!frList.includes(event.tags[0][1])) {
                // If the acc includes the current event's verifier
                // Or the event's verifier is the target user
                if (
                    acc.filter(
                        (accEvent) => accEvent.tags[0][1] === event.tags[0][1]
                    )?.length > 0 ||
                    event.tags[0][1] === targetPubkey
                ) {
                    // Don't add the event
                    return acc
                } else {
                    // Add the event
                    return [...acc, event as VEvent]
                }
            }

            // If the frList does include the current event's verifier,
            // then don't add the event to the acc
            return acc
        }, [] as VEvent[])

        // A list of recipients (to) who have been rescinded
        let trList: string[] = []

        const gList = rawVList.reduce((acc, event) => {
            // If this event isn't a Given Verification, then don't modify the acc
            if (event.tags[0][1] !== targetPubkey) {
                return acc
            }

            // The rest are Given Verifications From the Target User

            // If the event is a recission,
            // then add the recipient to the trList
            // and exit without updating the acc
            if ((event as VEvent).kind === 5556) {
                trList = [...trList, event.tags[1][1]]
                return acc
            }

            // If the trList doesn't include the current event's recipient
            if (!trList.includes(event.tags[1][1])) {
                // If the acc includes the current event's recipient
                // Or the event's recipient is the target user
                if (
                    acc.filter(
                        (accEvent) => accEvent.tags[1][1] === event.tags[1][1]
                    )?.length > 0 ||
                    event.tags[1][1] === targetPubkey
                ) {
                    // Don't add the event
                    return acc
                } else {
                    // Add the event
                    return [...acc, event as VEvent]
                }
            }

            // If the trList does include the current event's recipient,
            // then don't add the event to the acc
            return acc
        }, [] as VEvent[])

        return [vList, gList]
    }, [rawVList, targetPubkey])

    // useEffect(() => {
    //     console.log('Profile: rawVList:', rawVList)
    // }, [rawVList])

    const fromList: VerificationSummary[] = useMemo(() => {
        return Object.values(
            vList.reduce((map, item) => {
                const key = item.tags[0][1] as string
                return {
                    ...map,
                    [`${key}`]: {
                        pubkey: item.tags[0][1],
                        created_at: item.created_at,
                    },
                }
            }, {})
        )
    }, [vList])

    const toList: VerificationSummary[] = useMemo(() => {
        return Object.values(
            gList.reduce((map, item) => {
                const key = item.tags[1][1]
                return {
                    ...map,
                    [`${key}`]: {
                        pubkey: item.tags[1][1],
                        created_at: item.created_at,
                    },
                }
            }, {})
        )
    }, [gList])

    const [givensList, reciprocationsList] = useMemo(() => {
        let recipList: VerificationSummary[] = []

        const gList = toList.reduce((acc, to) => {
            const from = fromList.find((from) => from.pubkey === to.pubkey)
            if (from !== undefined && from.created_at < to.created_at) {
                recipList = [...recipList, from]
                return acc
            } else {
                return [...acc, to]
            }
        }, [] as VerificationSummary[])

        return [gList, recipList]
    }, [toList, fromList])

    const [receivedList, receivedReciprocationsList] = useMemo(() => {
        let recipList: VerificationSummary[] = []

        const receivedList = fromList.reduce((acc, from) => {
            const to = toList.find((to) => to.pubkey === from.pubkey)
            if (to !== undefined && to.created_at < from.created_at) {
                recipList = [...recipList, to]
                return acc
            } else {
                return [...acc, from]
            }
            // return [...acc, from]
        }, [] as VerificationSummary[])
        return [receivedList, recipList]
    }, [toList, fromList])

    const hasPrincipalVerifiedTarget = () => {
        return principalPubkey
            ? !!fromList.find((from) => from.pubkey === principalPubkey)
            : false
    }

    const isTargetAProgenitor = () => {
        return (
            fromList.find((from) => from.pubkey === appKeys.publicHex) !==
            undefined
        )
    }

    const isTargetThePrincipalUser = () => principalPubkey === targetPubkey

    const value = useMemo(() => {
        return {
            targetPubkey,
            rawVList,
            vList,
            gList,
            fromList,
            toList,
            givensList,
            reciprocationsList,
            receivedList,
            receivedReciprocationsList,
            hasPrincipalVerifiedTarget,
            isTargetAProgenitor,
            isTargetThePrincipalUser,
        }
    }, [
        targetPubkey,
        rawVList,
        vList,
        gList,
        fromList,
        toList,
        givensList,
        reciprocationsList,
        receivedList,
        receivedReciprocationsList,
        hasPrincipalVerifiedTarget,
        isTargetAProgenitor,
        isTargetThePrincipalUser,
    ])

    return (
        <TargetProfileContext.Provider value={value}>
            {children}
        </TargetProfileContext.Provider>
    )
}

export const useTargetProfile = () => {
    return useContext(TargetProfileContext)
}
