import { Box, Typography } from '@mui/material'

interface Props {
    children?: React.ReactNode
    index: number
    value: number
}

function ProfileTabPanel(props: Props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

export default ProfileTabPanel
