import React, { createContext, useContext, useMemo } from 'react'
import { useNostrEvents } from 'nostr-react'
import { Event as NostrEvent } from 'nostr-tools'
import { useParams } from 'react-router-dom'
import appKeys from './appKeys'
import releaseDate from './releaseDate'
import { NostrReactEvent, VerificationSummary, VEvent } from './types'
import { useAuth } from './useAuth'

type PrincipalProfileContextState = {
    targetPubkey: string
    principalRawVList: NostrReactEvent[]
    principalVList: VEvent[]
    principalGList: VEvent[]
    principalFromList: VerificationSummary[]
    principalToList: VerificationSummary[]
    principalGivensList: VerificationSummary[]
    principalReciprocationsList: VerificationSummary[]
    principalReceivedList: VerificationSummary[]
    hasTargetVerifiedPrincipal: () => boolean
    isPrincipalAProgenitor: () => boolean
    isPrincipalTheTargetUser: () => boolean
}

export const PrincipalProfileContext = createContext(
    {} as PrincipalProfileContextState
)

type Props = { children: React.ReactNode }

export const PrincipalProfileContextProvider: React.FC<Props> = ({
    children,
}) => {
    const { principalPubkey } = useAuth()
    const { pubkey } = useParams()

    if (!pubkey) {
        throw new Error('Target Profile Context: Target Pubkey not found')
    }

    const targetPubkey = useMemo(() => pubkey!, [pubkey])

    const { events: principalRawVList } = useNostrEvents({
        filter: {
            kinds: [5555, 5556],
            authors: [appKeys.publicHex],
            '#p': [principalPubkey!],
            since: releaseDate,
        },
    })

    const [principalVList, principalGList] = useMemo(() => {
        let frList: string[] = []

        const vList = principalRawVList.reduce((acc, event) => {
            // If the event is a recission,
            // then add the verifier to the frList
            // and exit without updating the acc
            if ((event as VEvent).kind === 5556) {
                frList = [...frList, event.tags[0][1]]
                return acc
            }

            // If the frList doesn't include the current event's verifier
            if (!frList.includes(event.tags[0][1])) {
                // If the acc includes the current event's verifier
                // Or the event's verifier is the principal user
                if (
                    acc.filter(
                        (accEvent) => accEvent.tags[0][1] === event.tags[0][1]
                    )?.length > 0 ||
                    event.tags[0][1] === principalPubkey
                ) {
                    // Don't add the event
                    return acc
                } else {
                    // Add the event
                    return [...acc, event as VEvent]
                }
            }

            // If the frList does include the current event's verifier,
            // then don't add the event to the acc
            return acc
        }, [] as VEvent[])

        // A list of recipients (to) who have been rescinded
        let trList: string[] = []

        const gList = principalRawVList.reduce((acc, event) => {
            // If this event isn't a Given Verification, then don't modify the acc
            if (event.tags[0][1] !== principalPubkey) {
                return acc
            }

            // The rest are Given Verifications From the Target User

            // If the event is a recission,
            // then add the recipient to the trList
            // and exit without updating the acc
            if ((event as VEvent).kind === 5556) {
                trList = [...trList, event.tags[1][1]]
                return acc
            }

            // If the trList doesn't include the current event's recipient
            if (!trList.includes(event.tags[1][1])) {
                // If the acc includes the current event's recipient
                // Or the event's recipient is the principal user
                if (
                    acc.filter(
                        (accEvent) => accEvent.tags[1][1] === event.tags[1][1]
                    )?.length > 0 ||
                    event.tags[1][1] === principalPubkey
                ) {
                    // Don't add the event
                    return acc
                } else {
                    // Add the event
                    return [...acc, event as VEvent]
                }
            }

            // If the trList does include the current event's recipient,
            // then don't add the event to the acc
            return acc
        }, [] as VEvent[])

        return [vList, gList]
    }, [principalRawVList, principalPubkey])

    // useEffect(() => {
    //     console.log('Profile: rawVList:', rawVList)
    // }, [rawVList])

    const principalFromList: VerificationSummary[] = useMemo(() => {
        return Object.values(
            principalVList.reduce((map, item) => {
                const key = item.tags[0][1] as string
                return {
                    ...map,
                    [`${key}`]: {
                        pubkey: item.tags[0][1],
                        created_at: item.created_at,
                    },
                }
            }, {})
        )
    }, [principalVList])

    const principalToList: VerificationSummary[] = useMemo(() => {
        return Object.values(
            principalGList.reduce((map, item) => {
                const key = item.tags[1][1]
                return {
                    ...map,
                    [`${key}`]: {
                        pubkey: item.tags[1][1],
                        created_at: item.created_at,
                    },
                }
            }, {})
        )
    }, [principalGList])

    const [principalGivensList, principalReciprocationsList] = useMemo(() => {
        let recipList: VerificationSummary[] = []

        const gList = principalToList.reduce((acc, to) => {
            const from = principalFromList.find(
                (from) => from.pubkey === to.pubkey
            )
            if (from !== undefined && from.created_at < to.created_at) {
                recipList = [...recipList, from]
                return acc
            } else {
                return [...acc, to]
            }
        }, [] as VerificationSummary[])

        return [gList, recipList]
    }, [principalToList, principalFromList])

    const principalReceivedList = useMemo(() => {
        return principalFromList.reduce((acc, from) => {
            const to = principalToList.find((to) => to.pubkey === from.pubkey)
            if (to !== undefined && to.created_at < from.created_at) {
                return acc
            } else {
                return [...acc, from]
            }
        }, [] as VerificationSummary[])
    }, [principalToList, principalFromList])

    const hasTargetVerifiedPrincipal = () => {
        return principalPubkey
            ? !!principalFromList.find((from) => from.pubkey === targetPubkey)
            : false
    }

    const isPrincipalAProgenitor = () => {
        return (
            principalFromList.find(
                (from) => from.pubkey === appKeys.publicHex
            ) !== undefined
        )
    }

    const isPrincipalTheTargetUser = () => principalPubkey === targetPubkey

    const value = useMemo(() => {
        return {
            targetPubkey,
            principalRawVList,
            principalVList,
            principalGList,
            principalFromList,
            principalToList,
            principalGivensList,
            principalReciprocationsList,
            principalReceivedList,
            hasTargetVerifiedPrincipal,
            isPrincipalAProgenitor,
            isPrincipalTheTargetUser,
        }
    }, [
        targetPubkey,
        principalRawVList,
        principalVList,
        principalGList,
        principalFromList,
        principalToList,
        principalGivensList,
        principalReciprocationsList,
        principalReceivedList,
        hasTargetVerifiedPrincipal,
        isPrincipalAProgenitor,
        isPrincipalTheTargetUser,
    ])

    return (
        <PrincipalProfileContext.Provider value={value}>
            {children}
        </PrincipalProfileContext.Provider>
    )
}

export const usePrincipalProfile = () => {
    return useContext(PrincipalProfileContext)
}
