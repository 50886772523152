import { useTargetProfile } from '../../app/useTargetProfile'
import Profile from './Profile'

const ProfileContainer = () => {
    const { targetPubkey } = useTargetProfile()
    // TODO: Redirect to not found screen if pubkey isn't present

    return (
        <div key={targetPubkey}>
            <Profile targetPubkey={targetPubkey!} />
        </div>
    )
}

export default ProfileContainer
