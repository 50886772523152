import { Box, Button, Divider, Typography } from '@mui/material'
import EmptyUserCardVertical from '../Cards/EmptyUserCardVertical'
import UserCardContainer from '../Cards/UserCardContainer'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import { useNavigate } from 'react-router-dom'
import { useTargetProfile } from '../../app/useTargetProfile'
import { useAuth } from '../../app/useAuth'
import { useEffect } from 'react'

const GivenVerificationsList = ({}) => {
    const { isPrincipalAppAccount } = useAuth()
    const {
        givensList,
        receivedList,
        reciprocationsList,
        fromList,
        isTargetAProgenitor,
        isTargetThePrincipalUser,
    } = useTargetProfile()
    const navigate = useNavigate()
    // useEffect(() => {
    //     console.log('GV List: Givens List:', givensList)
    // }, [givensList])

    const allowableGives = isTargetAProgenitor() ? 0 : 5

    let unlockedGives = receivedList.length - givensList.length
    const availableGives = allowableGives - givensList.length
    const unusedGives: React.ReactNode[] = []
    const getUnusedGives = () => {
        for (let i = 0; i < availableGives; i++) {
            unusedGives.push(
                <Box key={`empty-user-card-v-${i}`}>
                    <EmptyUserCardVertical label={getLabel(unlockedGives)} />
                </Box>
            )
            --unlockedGives
        }
        return unusedGives
    }

    const getLabel = (unlockedGives: number) => {
        // console.log('GV List: Unlocked Gives:', unlockedGives)
        if (!isTargetAProgenitor() && unlockedGives <= 0) {
            return (
                <Box display='flex' justifyContent='center'>
                    <LockOutlinedIcon
                        sx={{
                            width: '1.25rem',
                            height: '1.25rem',
                        }}
                    />
                </Box>
            )
        } else if (isTargetThePrincipalUser()) {
            return (
                <Button
                    onClick={() => navigate('/find-user')}
                    variant='text'
                    sx={{ mt: '-5px' }}
                >
                    Give
                </Button>
            )
        } else {
            return (
                <Box display='flex' justifyContent='center'>
                    <LockOpenOutlinedIcon
                        sx={{
                            width: '1.25rem',
                            height: '1.25rem',
                            color: '#4ade80',
                        }}
                    />
                </Box>
            )
        }
    }

    const isReciprocal = (toPubkey: string) => {
        return fromList.find((from) => from.pubkey === toPubkey) !== undefined
    }

    return (
        <>
            <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-evenly'
                alignItems='flex-start'
                flexWrap='wrap'
                gap={2}
            >
                {isTargetAProgenitor() && (
                    <Box key={`empty-user-card-v-progenitor`}>
                        <EmptyUserCardVertical
                            label={
                                <Button
                                    onClick={() => navigate('/find-user')}
                                    variant='text'
                                    sx={{ mt: '-5px' }}
                                >
                                    Give
                                </Button>
                            }
                        />
                    </Box>
                )}
                {givensList.map(
                    (to, i) =>
                        i < 15 && (
                            <Box key={`to-${to.pubkey}`}>
                                <UserCardContainer
                                    pubkey={to.pubkey}
                                    isReciprocal={isReciprocal(to.pubkey)}
                                />
                            </Box>
                        )
                )}
                {getUnusedGives()}
            </Box>

            {!!reciprocationsList && reciprocationsList.length > 0 && (
                <>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        sx={{ mt: '1rem', mb: '.5rem' }}
                    >
                        <Divider sx={{ width: '100%' }}>
                            Reciprocations Given
                        </Divider>
                    </Box>

                    <Box
                        display='flex'
                        justifyContent='center'
                        gap={1}
                        sx={{ my: '1rem' }}
                    >
                        <Box sx={{ pt: '2px' }}>
                            <InfoOutlinedIcon
                                sx={{
                                    mt: '-.5rem',
                                    color: '#777',
                                    borderRadius: '50%',
                                    width: '1.25rem',
                                    height: '1.25rem',
                                }}
                            />
                        </Box>
                        <Typography
                            variant='body2'
                            sx={{ mb: '1rem', textAlign: 'center' }}
                        >
                            These don't count as Given Verifications
                        </Typography>
                    </Box>

                    <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-evenly'
                        alignItems='flex-start'
                        flexWrap='wrap'
                        gap={2}
                    >
                        {reciprocationsList.map((to, i) => (
                            <Box key={`to-${to.pubkey}`}>
                                <UserCardContainer
                                    pubkey={to.pubkey}
                                    isReciprocal={isReciprocal(to.pubkey)}
                                />
                            </Box>
                        ))}
                    </Box>
                </>
            )}
        </>
    )
}

export default GivenVerificationsList
