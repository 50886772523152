import { Outlet } from 'react-router-dom'
import { TargetProfileContextProvider } from '../../app/useTargetProfile'

const TargetProfileWrapper = () => {
    return (
        <TargetProfileContextProvider>
            <Outlet />
        </TargetProfileContextProvider>
    )
}

export default TargetProfileWrapper
