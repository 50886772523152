import { dateToUnix, useNostr } from 'nostr-react'
import {
    UnsignedEvent as NostrUnsignedEvent,
    getEventHash,
    signEvent,
    getPublicKey,
} from 'nostr-tools'
import { NostrReactEvent } from './types'
import appKeys from './appKeys'

const usePublish = () => {
    const { publish } = useNostr()

    const createUnsignedNestedVerificationEvent = (
        fromPublicHex: string,
        toPublicHex: string,
        tagAsProgenitor: boolean = false
    ) => {
        const event: NostrUnsignedEvent = {
            kind: 5555,
            content: '',
            tags: [
                ['p', fromPublicHex],
                ['p', toPublicHex],
            ],
            created_at: dateToUnix(),
            pubkey: fromPublicHex,
        }

        if (tagAsProgenitor) {
            event.tags = [...event.tags, ['t', 'progenitor']]
        }

        return event
    }

    // const postVerification = (
    //     fromPublicHex: string,
    //     toPublicHex: string,
    //     tagAsProgenitor: boolean,
    //     nestedEvent: string
    // ) => {
    //     const event: NostrUnsignedEvent = {
    //         kind: 5555,
    //         content: nestedEvent,
    //         tags: [
    //             ['p', fromPublicHex],
    //             ['p', toPublicHex],
    //             ['c', 'NotaBot'],
    //             ['r', `https://notabot.net/profile/${fromPublicHex}`],
    //             ['r', `https://notabot.net/profile/${toPublicHex}`],
    //             ['d', `${fromPublicHex}:v:${toPublicHex}`],
    //             [
    //                 'subject',
    //                 '#[0] verified #[1] as NotaBot on https://NotaBot.net.',
    //             ],
    //         ],
    //         created_at: dateToUnix(),
    //         pubkey: getPublicKey(appKeys.privateHex!),
    //     }

    //     if (tagAsProgenitor) {
    //         event.tags = [...event.tags, ['t', 'progenitor']]
    //     }

    //     signAndBroadcast(event as NostrReactEvent, appKeys.privateHex)
    // }

    const createUnsignedNestedRecissionEvent = (
        fromPublicHex: string,
        toPublicHex: string
    ) => {
        const event: NostrUnsignedEvent = {
            kind: 5556,
            content: '',
            tags: [
                ['p', fromPublicHex],
                ['p', toPublicHex],
            ],
            created_at: dateToUnix(),
            pubkey: fromPublicHex,
        }
        return event
    }

    // const postRescission = (
    //     fromPublicHex: string,
    //     toPublicHex: string,
    //     nestedEvent: string
    // ) => {
    //     const event: NostrUnsignedEvent = {
    //         kind: 5556,
    //         content: nestedEvent,
    //         tags: [
    //             ['p', fromPublicHex],
    //             ['p', toPublicHex],
    //             ['d', `${fromPublicHex}:${toPublicHex}`],
    //             [
    //                 'subject',
    //                 '#[0] rescinds verification of #[1] as NotaBot on https://NotaBot.net.',
    //             ],
    //         ],
    //         created_at: dateToUnix(),
    //         pubkey: getPublicKey(appKeys.privateHex!),
    //     }

    //     signAndBroadcast(event as NostrReactEvent, appKeys.privateHex)
    // }

    // const postNoticeOfVerification = (fromPubkey: string, toPubkey: string) => {
    //     const event: NostrUnsignedEvent = {
    //         content: `#[0] verified #[1] as NotaBot on https://NotaBot.net`,
    //         kind: 1,
    //         tags: [
    //             ['p', fromPubkey],
    //             ['p', toPubkey],
    //         ],
    //         created_at: dateToUnix(),
    //         pubkey: appKeys.publicHex,
    //     }

    //     signAndBroadcast(event as NostrReactEvent, appKeys.privateHex)
    // }

    const sign = (event: NostrReactEvent, privateHex: string) => {
        event.id = getEventHash(event)
        event.sig = signEvent(event, privateHex)
        // console.log('Publish: Sign: Signed Event:', event)
        return event
    }

    const signAndBroadcast = (event: NostrReactEvent, privateHex?: string) => {
        event.id = getEventHash(event)
        event.sig = signEvent(event, privateHex!)

        // console.log('Publish: Sign and Broadcast: Event:', event)

        broadcastEvent(event)
    }

    const broadcastEvent = (event: NostrReactEvent) => {
        publish(event)
    }

    return {
        createUnsignedNestedVerificationEvent,
        // postVerification,
        createUnsignedNestedRecissionEvent,
        // postRescission,
        // postNoticeOfVerification,
        sign,
        signAndBroadcast,
        broadcastEvent,
    }
}

export default usePublish
