import { useNostrEvents } from 'nostr-react'
import React, { useEffect, useMemo } from 'react'
import { sortBy } from 'sort-by-typescript'
import MenuProfileCard from './MenuProfileCard'
import ProfileCard from './ProfileCard'

type Props = {
    pubkey: string
}

const MenuProfileCardContainer: React.FC<Props> = ({ pubkey }) => {
    // console.log('Profile Card: pubkey:', pubkey)

    const author = useMemo(() => {
        return pubkey
    }, [pubkey])

    const { events: users } = useNostrEvents({
        filter: {
            authors: [author],
            kinds: [0],
            since: 0,
            limit: 1,
        },
    })

    // useEffect(() => {
    //     console.log('Profile Card: Users', users)
    // })

    const getUserContent = () => users.sort(sortBy('-created_at'))[0]?.content

    return <MenuProfileCard userContent={getUserContent()} />
}

export default React.memo(MenuProfileCardContainer)
