import { Box, Avatar, Typography } from '@mui/material'
import React from 'react'

interface Props {
    label?: React.ReactNode
}

const EmptyUserCardVertical = ({ label }: Props) => {
    if (!label) {
        label = (
            <Typography
                variant='body2'
                fontWeight='fontWeightRegular'
                color='text.primary'
                sx={{ textDecoration: 'none' }}
            >
                Needed
            </Typography>
        )
    }

    return (
        <>
            {/* <NavLink
                to={`/profile/${pubkey}`}
                style={{ textDecoration: 'none' }}
            > */}
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                sx={{
                    pb: '.5rem',
                    border: '1px solid #eee',
                    borderRadius: '.5rem',
                    // boxShadow: '1',
                    maxWidth: '7rem',
                }}
            >
                <Avatar
                    variant='square'
                    src=''
                    sx={{
                        width: '7rem',
                        height: '7rem',
                        borderRadius: '.5rem .5rem 0 0',
                        backgroundColor: '#eee',
                        color: 'text.primary',
                    }}
                />
                <Box
                    sx={{
                        m: '.5rem .5rem 0 .5rem',
                        width: '90px',
                        height: '25px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipses',
                        // border: '1px solid gray',
                        textAlign: 'center',
                    }}
                >
                    {/* <Typography
                        variant='body2'
                        fontWeight='fontWeightRegular'
                        color='text.primary'
                        sx={{ textDecoration: 'none' }}
                    > */}
                    {label}
                    {/* </Typography> */}
                </Box>
            </Box>
            {/* </NavLink> */}
        </>
    )
}

export default React.memo(EmptyUserCardVertical)
