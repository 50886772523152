import { NavLink } from 'react-router-dom'

type Props = {
    to: string
    children: React.ReactNode
}

const AppLink: React.FC<Props> = ({ to, children }) => (
    <NavLink
        to={to}
        style={{
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        {children}
    </NavLink>
)

export default AppLink
