import { Box, Button, Stack, Typography } from '@mui/material'
import AppTextField from '../../components/FormControls/AppTextField'
import { SignInFields } from './SignIn'
import { Field, Form, useFormikContext } from 'formik'
import { useEffect } from 'react'
import SignerComponent from '../../components/Signer/SignerComponent'
import { useAuth } from '../../app/useAuth'

interface Props {
    onActivateValidation: () => void
}

const SignInForm: React.FC<Props> = ({ onActivateValidation }) => {
    const { hasWindowDotNostr } = useAuth()
    const { values, errors, setFieldValue } = useFormikContext<SignInFields>()

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0) {
            onActivateValidation()
        }
    }, [errors, onActivateValidation])

    useEffect(() => {
        if (!!setFieldValue) {
            setFieldValue('hasWindowDotNostr', hasWindowDotNostr!)
        }
    }, [hasWindowDotNostr, setFieldValue])

    return (
        <Form id='sign-in-form'>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            <SignerComponent formName='sign-in-form' buttonLabel='Sign In' />
        </Form>
    )
}

export default SignInForm
