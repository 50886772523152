import { Box, Typography, Button } from '@mui/material'
import { useAuth } from '../../app/useAuth'
import usePublish from '../../app/usePublish'
import AppLink from '../../components/FormControls/AppLink'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useSearchParams } from 'react-router-dom'
import { NostrReactEvent } from '../../app/types'
import { useTargetProfile } from '../../app/useTargetProfile'
import { signRescission } from '../../app/endpoints'

export type RescissionPayload = {
    fromPublicHex: string
    toPublicHex: string
    nestedEvent: string
}

const PrincipalVerificationStatus = () => {
    const {
        isSignedIn,
        principalPubkey,
        principalPrivateKey,
        hasWindowDotNostr,
    } = useAuth()
    const { targetPubkey, hasPrincipalVerifiedTarget } = useTargetProfile()
    const {
        createUnsignedNestedRecissionEvent,
        // postRescission,
        sign,
        broadcastEvent,
    } = usePublish()
    const [searchParams] = useSearchParams()

    const rescindVerification = async () => {
        const unsignedNestedRecissionEvent = createUnsignedNestedRecissionEvent(
            principalPubkey!,
            targetPubkey
        )

        // let principalPubkey: string = ''

        if (hasWindowDotNostr) {
            try {
                const signedNestedRecissionEvent =
                    // @ts-ignore: Property nostr does not exist
                    await window.nostr.signEvent(unsignedNestedRecissionEvent)
                console.log(
                    'Principal Verification Status: Signed R Event:',
                    signedNestedRecissionEvent
                )

                const signedRescissionResp = await signRescission({
                    fromPublicHex: principalPubkey!,
                    toPublicHex: targetPubkey,
                    nestedEvent: JSON.stringify(signedNestedRecissionEvent),
                })
                console.log(
                    'Principal Verification Status: Signed R Event:',
                    signedRescissionResp.data
                )

                broadcastEvent(signedRescissionResp.data as NostrReactEvent)

                // postRescission(
                //     principalPubkey!,
                //     targetPubkey,
                //     JSON.stringify(signedNestedRecissionEvent)
                // )
            } catch (e) {
                console.error('Principal Verification Status:', e)
                return
            }
        } else {
            try {
                const signedNestedRecissionEvent = sign(
                    unsignedNestedRecissionEvent as NostrReactEvent,
                    principalPrivateKey!
                )

                const signedRescissionResp = await signRescission({
                    fromPublicHex: principalPubkey!,
                    toPublicHex: targetPubkey,
                    nestedEvent: JSON.stringify(signedNestedRecissionEvent),
                })
                console.log(
                    'Principal Verification Status: Signed R Event:',
                    signedRescissionResp.data
                )

                broadcastEvent(signedRescissionResp.data as NostrReactEvent)

                // postRescission(
                //     principalPubkey!,
                //     targetPubkey,
                //     JSON.stringify(signedRescissionEvent)
                // )
            } catch (e) {
                console.error(e)
            }
            console.log(
                'Principal Verification Status: Post Verification: Success'
            )
        }
    }

    const isPrincipalSameAsTarget = () => {
        return principalPubkey === targetPubkey
    }

    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            sx={{ pt: '1.5rem' }}
        >
            {isSignedIn() && !isPrincipalSameAsTarget() && (
                <>
                    {hasPrincipalVerifiedTarget() ? (
                        <Box display='flex' flexDirection='column' gap={0}>
                            <Box display='flex' gap={1}>
                                <Box sx={{ pt: '3px' }}>
                                    <CheckCircleRoundedIcon
                                        sx={{
                                            color: 'white',
                                            backgroundColor: '#4ade80',
                                            borderRadius: '50%',
                                        }}
                                    />
                                </Box>
                                <Typography
                                    component='span'
                                    variant='h6'
                                    fontWeight='fontWeightRegular'
                                >
                                    You have verified this user.
                                </Typography>
                            </Box>
                            {/* <Button
                                size='medium'
                                onClick={() => rescindVerification()}
                                sx={{
                                    fontWeight: 'fontWeightRegular',
                                    textTransform: 'none',
                                    color: '#777',
                                }}
                            >
                                Rescind
                            </Button> */}
                        </Box>
                    ) : (
                        <>
                            {' '}
                            <Typography
                                variant='h6'
                                fontWeight='fontWeightRegular'
                            >
                                You have not verified this user.
                            </Typography>
                            {/* <AppLink to={`/verify/${targetPubkey}`}>
                                <Typography
                                    component='span'
                                    color='primary'
                                    sx={{
                                        fontSize: '1.5rem',
                                    }}
                                >
                                    Verify Now
                                </Typography>{' '}
                                <ChevronRightIcon
                                    color='primary'
                                    style={{ fontSize: '1.5rem' }}
                                />
                            </AppLink> */}
                        </>
                    )}
                </>
            )}
        </Box>
    )
}

export default PrincipalVerificationStatus
