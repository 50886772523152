import * as yup from 'yup'

const useFindUserValidation = () => {
    const validationSchema = yup.object().shape({
        username: yup
            .string()
            .trim()
            .required(`Either a username (NIP-05) or Public Key is required.`),
    })

    return {
        validationSchema,
    }
}

export default useFindUserValidation
