import { Box, Typography, Button } from '@mui/material'
import { Stack } from '@mui/system'
import { Formik, Form, FormikHelpers } from 'formik'
import { nip05, nip19 } from 'nostr-tools'
import { ProfilePointer } from 'nostr-tools/nip19'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FindUserFields from './FindUserFields'
import useFindUserValidation from './useFindUserValidation'

export type Fields = {
    username: string
}

const FindUser = () => {
    const { validationSchema } = useFindUserValidation()
    const initialValues: Fields = { username: '' }
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleActivateValidation = () => {
        setValidateOnChange(true)
    }

    const handleSubmit = async (
        fields: Fields,
        { setFieldError }: FormikHelpers<Fields>
    ) => {
        console.log('Find User: Submit called...')

        let pubkey = ''

        if (fields.username.includes('.')) {
            try {
                const foundUser: ProfilePointer | null | undefined =
                    await searchNip05(fields.username)

                console.log('Find User: Found User:', foundUser)

                if (foundUser?.pubkey) {
                    pubkey = foundUser.pubkey
                } else {
                    setFieldError('username', 'This user was not found.')
                    return
                }
            } catch (e) {
                console.error(e)
                setFieldError('username', 'This user was not found.')
                return
            }
        } else if (fields.username.startsWith('npub')) {
            pubkey = nip19.decode(fields.username).data as string
        } else {
            pubkey = fields.username
        }

        console.log('Find User: Pubkey:', pubkey)

        navigate(`/profile/${pubkey}`)
    }

    const searchNip05 = async (username: string) => {
        try {
            return await nip05.queryProfile(username)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <Box
                sx={{
                    pt: '2rem',
                    pb: '1rem',
                    px: '1rem',
                    // textAlign: 'center',
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnChange={validateOnChange}
                    validateOnBlur={validateOnChange}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                    }) => {
                        const formikProps = {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }

                        return (
                            <Form id='verify-friend-form'>
                                {/* {JSON.stringify(values)} */}
                                <Stack
                                    spacing={2}
                                    sx={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: '100%',
                                        border: '0px solid gray',
                                    }}
                                >
                                    <Stack>
                                        <Typography variant='h5'>
                                            Find a User
                                        </Typography>
                                    </Stack>
                                    <FindUserFields
                                        onActivateValidation={
                                            handleActivateValidation
                                        }
                                        {...formikProps}
                                    />
                                    <Stack>
                                        <Button
                                            // onClick={handleSubmit}
                                            type='submit'
                                            form='verify-friend-form'
                                            variant='contained'
                                            color='primary'
                                        >
                                            <Typography
                                                color='primary.contrastText'
                                                fontWeight='fontWeightMedium'
                                            >
                                                Search
                                            </Typography>
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Form>
                        )
                    }}
                </Formik>
            </Box>
        </>
    )
}

export default FindUser
