import { Stack, Typography } from '@mui/material'
import { ProfileData } from '../../app/types'
import DisplayName from './DisplayName'

type Props = {
    profileData: ProfileData
}

const ProfileCardDisplayName: React.FC<Props> = ({ profileData }) => {
    // console.log('Profile Card Display Name: profileData:', profileData)
    const getBestDisplayName = () => {
        if (!!profileData?.display_name) {
            return profileData.display_name
        } else if (!!profileData?.name) {
            return profileData.name
        } else {
            return null
        }
    }

    const getNpub = () => {
        if (!!profileData?.npub) {
            return `${profileData?.npub.substring(
                0,
                11
            )}...${profileData?.npub.slice(profileData?.npub.length - 6)}`
        } else {
            return null
        }
    }

    const showNpub = () => {
        return (
            !profileData?.display_name &&
            !profileData?.name &&
            !profileData?.nip05
        )
    }
    return (
        <Stack>
            {!!getBestDisplayName() && (
                <Typography variant='h5'>{getBestDisplayName()}</Typography>
            )}

            {!!profileData.nip05 && (
                <Typography variant='body1'>{profileData.nip05}</Typography>
            )}

            {!!showNpub() && (
                <Typography variant='h6' fontWeight='fontWeightRegular'>
                    {getNpub()}
                </Typography>
            )}
        </Stack>
    )
}

export default ProfileCardDisplayName
