import { Route, Routes } from 'react-router-dom'
import SignIn from './screens/SignIn/SignIn'
import Terms from './screens/Terms/Terms'
import About from './screens/About/About'
// import CreateBadge from './screens/Badge/CreateBadge'
import ScrollToTop from './components/Layout/ScrollToTop'
import ProfileContainer from './screens/Profile/ProfileContainer'
import TargetProfileWrapper from './screens/Profile/TargetProfileWrapper'
import Layout from './components/Layout/Layout'
import FindUser from './screens/FindUser/FindUser'
import VerifyUser from './screens/VerifyUser/VerifyUser'
import PrincipalProfileWrapper from './screens/Profile/PrincipalProfileWrapper'

const AppRouter = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<SignIn />} />
                    <Route path='terms' element={<Terms />} />
                    <Route path='about' element={<About />} />
                    <Route path='find-user' element={<FindUser />} />
                    {/* <Route
                        path='profile/:pubkey'
                        element={<TargetProfileWrapper />}
                    >
                        <Route index element={<ProfileContainer />} />
                    </Route>
                    <Route path='verify-human' element={<VerifyUser />} /> */}
                    <Route element={<TargetProfileWrapper />}>
                        <Route
                            path='profile/:pubkey'
                            element={<ProfileContainer />}
                        />
                    </Route>
                    <Route element={<PrincipalProfileWrapper />}>
                        <Route path='verify/:pubkey' element={<VerifyUser />} />
                    </Route>
                    <Route element={<PrincipalProfileWrapper />}>
                        <Route path='verify/:pubkey' element={<VerifyUser />} />
                    </Route>
                </Route>
                {/* <Route element={<LoginCheck />}>
                    <Route element={<EarnedBadgesWrapper />}>
                        <Route
                            path='badges/earned'
                            element={<EarnedBadgesContainer />}
                        />
                    </Route>
                </Route> */}
            </Routes>
        </ScrollToTop>
    )
}

export default AppRouter
