const appKeys = {
    // nsec: 'nsec15hcng7hnhcgftagknst9pnwmsxuswau5a5vevam7ncf45d8ggk3smcy6tl',
    // privateHex:
    //     'a5f1347af3be1095f5169c1650cddb81b9077794ed1996777e9e135a34e845a3',
    npub: 'npub1rg2wzhmtefyh6cjg334setzf26q7yl5jt5v08etrccqlezvm5f6q28ku23',
    publicHex:
        '1a14e15f6bca497d62488c6b0cac495681e27e925d18f3e563c601fc899ba274',
}

export default appKeys
