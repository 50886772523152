import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import appKeys from './appKeys'

type AuthContextState = {
    isSignedIn: () => boolean
    signin: (
        pubkey: string,
        privateKey?: string | null,
        targetUrl?: string
    ) => void
    signout: () => void
    getPubkey: () => string | null
    getPrivateKey: () => string | null
    principalPubkey: string | null
    principalPrivateKey: string | null
    hasWindowDotNostr: boolean
    isPrincipalAppAccount: () => boolean
}

export const AuthContext = createContext({} as AuthContextState)

type Props = { children: React.ReactNode }

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate()
    const isFirstRender = useRef(true)
    const [hasWindowDotNostr, setHasWindowDotNostr] = useState(false)

    const signin = (
        pubkey: string,
        privateKey?: string | null,
        targetUrl?: string
    ) => {
        localStorage.setItem('pubkey', pubkey)
        if (!!privateKey) {
            localStorage.setItem('privkey', privateKey)
        }
        if (!!targetUrl) {
            navigate(targetUrl)
        } else {
            navigate(`/profile/${pubkey}`)
        }
    }

    const isSignedIn = () => {
        return !!localStorage.getItem('pubkey')
    }

    const signout = () => {
        localStorage.removeItem('pubkey')
        localStorage.removeItem('privkey')
        navigate('/')
    }

    const getPubkey = () => {
        return localStorage.getItem('pubkey')
    }

    const getPrivateKey = () => {
        return localStorage.getItem('privkey')
    }

    const principalPubkey = useMemo(() => {
        return !!getPubkey() ? getPubkey() : null
    }, [getPubkey])

    const principalPrivateKey = useMemo(() => {
        return !!getPrivateKey() ? getPrivateKey() : null
    }, [getPrivateKey])

    useEffect(() => {
        if (isFirstRender.current) {
            window.onload = () => {
                console.log('Use Auth: window.onload called...')
                // @ts-ignore: Property nostr does not exist
                if (!!window.nostr) {
                    setHasWindowDotNostr(true)
                }
                isFirstRender.current = false
            }
        }
    }, [isFirstRender])

    const isPrincipalAppAccount = () => {
        return principalPubkey === appKeys.publicHex
    }

    const value = {
        isSignedIn,
        signin,
        signout,
        getPubkey,
        getPrivateKey,
        principalPubkey,
        principalPrivateKey,
        hasWindowDotNostr,
        isPrincipalAppAccount,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}
