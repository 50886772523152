import { Alert, Box, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import ProfileCardContainer from '../../components/Cards/ProfileCardContainer'
import ProfileTabPanel from './ProfileTabPanel'
import PrincipalVerificationStatus from './PrincipalVerificationStatus'
import VerificationsList3 from '../../components/Verifications/VerificationsList3'
import CheckIcon from '@mui/icons-material/Check'
import GivenVerificationsList from '../../components/Verifications/GivenVerificationsList'
import SyncIcon from '@mui/icons-material/Sync'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'
import { useTargetProfile } from '../../app/useTargetProfile'
import VerificationsList4 from '../../components/Verifications/VerificationsList4'

type Props = {
    targetPubkey: string
}

const Profile: React.FC<Props> = ({ targetPubkey }) => {
    const {
        receivedList,
        givensList,
        isTargetAProgenitor,
        isTargetThePrincipalUser,
    } = useTargetProfile()
    const [selectedTab, setSelectedTab] = useState(0)

    // console.log('Profile: Target Pubkey:', targetPubkey)

    const handleTabChange = (
        event: React.SyntheticEvent,
        newTabValue: number
    ) => {
        setSelectedTab(newTabValue)
    }

    return (
        <Box
            sx={{
                pt: '1.5rem',
                pb: '1rem',
                width: '100%',
                // border: '1px solid gray',
            }}
        >
            <PrincipalVerificationStatus />
            {/* <div>
                <pre>{JSON.stringify(principalProfilesList[0], null, 2)}</pre>
                {isSignedIn() && hasPrincipalVerifiedTarget.toString()}
            </div> */}
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-evenly'
                alignItems='center'
            >
                {!!targetPubkey ? (
                    // <ProfileCard
                    <div key={targetPubkey}>
                        <ProfileCardContainer pubkey={targetPubkey!} />
                    </div>
                ) : (
                    <Alert severity='error'>No public key provided.</Alert>
                )}
            </Box>

            <Typography
                variant='body1'
                fontWeight='fontWeightBold'
                sx={{
                    mt: '1rem',
                    mb: '.5rem',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                }}
            >
                Verifications
            </Typography>

            {/* Let's add some tabs here */}
            <Box
                display='flex'
                justifyContent='center'
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label='basic tabs example'
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Tab
                        label='Received'
                        {...a11yProps(1)}
                        sx={{ mr: '2rem' }}
                    />
                    <Tab label='Given' {...a11yProps(0)} sx={{ ml: '2rem' }} />
                </Tabs>
            </Box>
            <Box sx={{ my: '1rem', minHeight: '12rem' }}>
                <ProfileTabPanel value={selectedTab} index={0}>
                    {!isTargetAProgenitor() && (
                        <Box
                            display='flex'
                            justifyContent='center'
                            gap={1}
                            sx={{ my: '1rem' }}
                        >
                            {!!receivedList && receivedList.length >= 5 && (
                                <Box sx={{ pt: '2px' }}>
                                    <CheckIcon
                                        sx={{
                                            color: '#4ade80',
                                            borderRadius: '50%',
                                            width: '1rem',
                                            height: '1rem',
                                        }}
                                    />
                                </Box>
                            )}
                            <Typography
                                component='span'
                                variant='body1'
                                fontWeight='fontWeightRegular'
                            >
                                Receive at least 5 verifications
                            </Typography>
                        </Box>
                    )}

                    {isTargetAProgenitor() && (
                        <Box
                            display='flex'
                            justifyContent='center'
                            gap={1}
                            sx={{ my: '1rem' }}
                        >
                            <Box sx={{ pt: '2px' }}>
                                <CheckIcon
                                    sx={{
                                        color: '#4ade80',
                                        borderRadius: '50%',
                                        width: '1rem',
                                        height: '1rem',
                                    }}
                                />
                            </Box>
                            <Typography
                                component='span'
                                variant='body1'
                                fontWeight='fontWeightRegular'
                            >
                                Progenitor
                            </Typography>
                        </Box>
                    )}
                    {!!targetPubkey && <VerificationsList4 />}
                </ProfileTabPanel>
                <ProfileTabPanel value={selectedTab} index={1}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        gap={1}
                        sx={{ my: '1rem' }}
                    >
                        {isTargetThePrincipalUser() && (
                            <>
                                {!!givensList && givensList.length >= 5 && (
                                    <Box sx={{ pt: '2px' }}>
                                        <CheckIcon
                                            sx={{
                                                color: '#4ade80',
                                                borderRadius: '50%',
                                                width: '1rem',
                                                height: '1rem',
                                            }}
                                        />
                                    </Box>
                                )}
                                <Typography
                                    component='span'
                                    variant='body1'
                                    fontWeight='fontWeightRegular'
                                >
                                    {isTargetAProgenitor() ? (
                                        <>
                                            As a progenitor, just verify the
                                            humans you know.
                                        </>
                                    ) : (
                                        <>Give at least 5 verifications</>
                                    )}
                                </Typography>
                            </>
                        )}
                    </Box>

                    {!!targetPubkey && <GivenVerificationsList />}
                </ProfileTabPanel>
            </Box>

            {/* <Box sx={{ p: '1rem 0 2rem 0' }}>
                <VerificationCount count={fromList.length} />
            </Box> */}
            {(givensList.length > 0 || receivedList.length > 0) && (
                <Box
                    display='flex'
                    justifyContent='center'
                    gap={1}
                    sx={{ mt: '5rem' }}
                >
                    <Box
                        display='flex'
                        sx={{
                            p: '.5rem',
                            pb: '.2rem',
                            border: '1px solid lightgray',
                            borderRadius: '.25rem',
                        }}
                    >
                        <Box display='flex' sx={{ mr: '.5rem' }}>
                            <Box sx={{ pt: '2px', pr: '.25rem' }}>
                                <SyncIcon
                                    sx={{
                                        mt: '-.5rem',
                                        color: '#777',
                                        borderRadius: '50%',
                                        width: '1.25rem',
                                        height: '1.25rem',
                                    }}
                                />
                            </Box>
                            <Typography
                                variant='body2'
                                sx={{ textAlign: 'center' }}
                            >
                                Reciprocal
                            </Typography>
                        </Box>

                        <Box display='flex' sx={{ ml: '.5rem' }}>
                            <Box sx={{ pt: '2px', pr: '.25rem' }}>
                                <SyncDisabledIcon
                                    sx={{
                                        mt: '-.5rem',
                                        color: '#777',
                                        borderRadius: '50%',
                                        width: '1.25rem',
                                        height: '1.25rem',
                                    }}
                                />
                            </Box>
                            <Typography
                                variant='body2'
                                sx={{ textAlign: 'center' }}
                            >
                                Non-Reciprocal
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default Profile
