export const relayUrls: string[] = [
    'wss://apprelay.notabot.net',

    // 'wss://relay.wellorder.net',
    'wss://relay.damus.io', // check.
    'wss://brb.io', // check. Has my profile
    'wss://relay.nostr.info', // check. Good. Returns most followed (125). Updates.
    'wss://relay.nostr.bg', // check.
    // 'wss://relay.oxtr.dev', // check.
    'wss://nostr-pub.wellorder.net', // check. Okay. Has my profile. Returns fewer followed (121). Doesn't update.
    'wss://nostr.fmt.wiz.biz', // check.
    // 'wss://nostr.semisol.dev', // check. Bad
    'wss://nostr-relay.untethr.me', // Good. Returns most followed (125). Updates.
    'wss://eden.nostr.land', // check.
    'wss://relay.snort.social', // check.
    'wss://offchain.pub', // check.
    'wss://nos.lol', // check.
    // 'wss://relay.current.fyi', // check.
]
