import { useNostrEvents } from 'nostr-react'
import React, { useEffect, useMemo } from 'react'
import { sortBy } from 'sort-by-typescript'
import ProfileCard from './ProfileCard'

type Props = {
    pubkey: string
}

const ProfileCardContainer: React.FC<Props> = ({ pubkey }) => {
    // console.log('Profile Card Container: pubkey:', pubkey)

    const author = useMemo(() => {
        return pubkey
    }, [pubkey])

    const { events: users } = useNostrEvents({
        filter: {
            authors: [pubkey],
            kinds: [0],
            since: 0,
            // limit: 1,
        },
    })

    // useEffect(() => {
    //     console.log('Profile Card: Users', users)
    // }, [users])

    const getUserContent = () => users.sort(sortBy('-created_at'))[0]?.content

    return (
        <>
            {users.length > 0 && (
                <ProfileCard userContent={getUserContent()} pubkey={pubkey} />
            )}
        </>
    )
}

export default React.memo(ProfileCardContainer)
