import { Box, Typography, Alert } from '@mui/material'

const AboutKeyManagement = () => (
    <Box>
        <Alert severity='info' sx={{ mt: '.5rem' }}>
            Signing extensions are fully supported. Using one is recommended.
        </Alert>

        <Typography sx={{ pt: '1rem' }}>
            It's best to not login to any website or app with your Private Key.
            But if you must, here's how your key is handled in this app:
        </Typography>

        <ul style={{ paddingLeft: '15px' }}>
            <Typography variant='body1'>
                {/* <li>
                    Your Private Key is only used to sign the verification note.
                </li> */}
                {/* <li>
                    It is not persisted in any way unless you explicitely allow
                    it for future convenience.
                </li> */}
                <li>
                    Your Private Key will be stored in your browser's Local
                    Storage. This option should not be used on public or shared
                    devices.
                </li>
                <li>Your Private Key is never passed over the wire.</li>
            </Typography>
        </ul>
    </Box>
)

export default AboutKeyManagement
