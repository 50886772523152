import axios from 'axios'
import getEnvVars from './envVars'

const ENV_VARS = getEnvVars()

console.log('Axios Config: Env Vars:', ENV_VARS)

const instance = axios.create({
    baseURL: ENV_VARS.API_URL,
    headers: {},
})

export default instance
