import { Box, Divider, Typography } from '@mui/material'

const HowItWorksContent = () => {
    return (
        <>
            <Typography variant='subtitle2' sx={{ pt: '1rem' }}>
                Table of Contents
            </Typography>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <a href='#what-is-verified-human'>What is NotABot?</a>
                </li>
                <li>
                    <a href='#authenticity-anonymity-autonomy'>
                        Authenticity, Anonymity, and Autonomy
                    </a>
                </li>
                <li>
                    <a href='#how-it-works'>How it Works?</a>
                </li>

                <ul>
                    <li>
                        <a href='#verification-explanation'>
                            What Does it Mean to Verify Someone as Human?
                        </a>
                    </li>
                    {/* <li>
                        <a href='#evidence'>Evidence</a>
                    </li> */}
                    <li>
                        <a href='#progenitors'>Progenitors</a>
                    </li>
                    <li>
                        <a href='#fully-verified-humans'>
                            Fully verified NotABots
                        </a>
                    </li>
                    <li>
                        <a href='#rescinding-verifications'>
                            Rescinding Verifications
                        </a>
                    </li>
                    <li>
                        <a href='#reporting'>Reporting</a>
                    </li>
                    <li>
                        <a href='#costliness'>Costliness (our new tool)</a>
                    </li>
                </ul>

                <li>
                    <a href='#how-is-data-managed'>How is Data Managed?</a>
                </li>
                <li>
                    <a href='#how-is-verified-human-structured'>
                        How is NotABot Structured?
                    </a>
                </li>
            </ul>

            <Typography
                variant='h6'
                fontWeight='fontWeightRegular'
                id='what-is-verified-human'
            >
                What is NotABot?
            </Typography>
            <p>
                NotABot fights spam by combining a reputation system with social
                proof of work to build a scalable chain of trust among
                participants.
            </p>
            <p>
                It provides a heuristics based digital credential for relays,
                apps, etc. to inspect when determining which accounts can access
                resources (such as notes on a relay). When combined with paid
                access, relay runners might be able to provide a nearly spam
                free service.
            </p>

            <Typography
                variant='h6'
                fontWeight='fontWeightRegular'
                id='authenticity-anonymity-autonomy'
            >
                Authenticity, Anonymity, and Autonomy
            </Typography>
            <p>
                NotABot attempts to marry three values that Nostriches tend to
                care about. We want:
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    Authenticity - Real people without a ton of bots harshing
                    our mellow
                </li>
                <li>
                    Anonymity - Nym friendly. No paying for verification with
                    identity revealing credit card payments of $8/mo.
                </li>
                <li>
                    Autonomy - Protocols over Platforms. No centralized
                    authorities or ad-based incentive structures manipulating
                    our behaviors to manufacture consent or drive profit
                    margins. We are building Value-4-Value, Peer-to-Peer
                    communications and commerce.
                </li>
            </ul>
            <p>
                All three, bot free. That's the dream. I know we'll make it
                happen.
            </p>

            {/* <Typography variant='h6' fontWeight='fontWeightRegular' id='value-of-verified-human'>
                What Value Does NotABot Provide?
            </Typography>
            <p>
                NotABot 
                <ul>
                    <li>
                        Relays can require that an account has a Verified
                        Human credential as a condition of access; greatly
                        reducing access to bots.
                    </li>
                    <li>
                        Apps and relays can offload a good bit of the spam
                        fighting function to a community supported system
                        that exists solely for that purpose allowing them to
                        focus on building out NIPs, supporting users, and
                        innovating as service providers.
                    </li>
                    <li>
                        Apps and relays can offload a good bit of the spam
                        fighting function to a community supported system
                        that exists solely for that purpose allowing them to
                        focus on building out NIPs, supporting users, and
                        innovating as service providers.
                    </li>
                </ul>
            </p> */}

            <Typography
                variant='h6'
                fontWeight='fontWeightRegular'
                id='how-it-works'
            >
                How it Works
            </Typography>

            <Typography
                variant='subtitle2'
                sx={{ pt: '1rem' }}
                id='verification-explanation'
            >
                What Does it Mean to Verify Someone as Human?
            </Typography>
            <p>
                NotABot is a verified credential system built on Nostr. It has
                only one credential a person can earn, and that is the
                credential of being human and not a spam bot. It's pretty much
                the most basic credential a person could ever receive, but in
                the battle against spam, it means almost everything.
            </p>
            <p>
                Sure people can be spammers too, but it is easy to mute, block,
                or unfollow the occasional over-promoting human. It is much more
                difficult to keep up with the deluge of bots.
            </p>
            {/* there is proof of work involved in creating and
                maintaining a human; bots, on the other hand, can be spun up en
                mass. Proof of Work continues to be the difference maker. It */}
            <p>
                When you <b style={{ fontWeight: '700' }}>Verify</b> somebody,
                all you are saying is that to the best of your knowledge the
                account you are Verifying is operated by a human. That's it. You
                are making no other claims about them.
            </p>
            <p>
                It should be noted that identity has nothing to do with
                Verification. The point isn't that we know
                <i>who</i> someone is, only that they are human and not a bot.
            </p>

            {/* <Typography variant='subtitle2' id='evidence'>
                Evidence
            </Typography>
            <p>
                When you verify someone, you'll be able to specify the extent of
                evidence you have that they're not a bot. E.g., Met in person,
                video chat, audio chat, text chat, etc. It is not necessary to
                meet in person or to ever see someone's face to verify them as
                human.
            </p> */}

            <Typography variant='subtitle2' id='progenitors'>
                Progenitors
            </Typography>
            <p>
                So how do we keep bots out of the NotABot network? It starts
                with <b style={{ fontWeight: '700' }}>Progenitors</b>. At the
                Nostrica conference, five Progenitors will be announced. These
                Progenitors will have the ability to Verify other Nostr users as
                humans.
            </p>
            <p>
                The Progenitors will be selected based on their reputations as
                principled defenders of the Nostr protocol and their willingness
                to Verify a bunch of folks. All NotABots will be descendants of
                these five Progenitors.
                {/* Try to collect all five by being Verified by
                someone in each Progenitor's chain. */}
            </p>

            <Typography variant='subtitle2' id='fully-verified-humans'>
                Fully Verified NotABots
            </Typography>
            <p>
                A <b style={{ fontWeight: '700' }}>Fully Verified NotABot</b> is
                one who has been verified by five other Fully Verified NotABots.{' '}
                {/* <i>Only Fully Verified NotABots can verify other humans.</i>  */}
                Progenitors are the exception, which is what makes them
                Progenitors.
            </p>

            <Typography variant='subtitle2' id='rescinding-verifications'>
                Rescinding Verifications
            </Typography>
            <p>
                If you verify an account that turns out to be a spam bot, you
                can <b style={{ fontWeight: '700' }}>Rescind</b> your
                verification of that account, or otherwise risk your verifiers
                Rescinding their verification of you.
            </p>

            <Typography variant='subtitle2' id='reporting'>
                Reporting
            </Typography>
            <p>
                Suspected bots can be{' '}
                <b style={{ fontWeight: '700' }}>Reported</b> by Fully Verified
                NotABots, but also <i>primarily</i> by service providers; e.g.,
                relay operators, community managers, etc. The Verifiers of the
                reported accounts will be notified and given the opportunity to
                Rescind their verification of those accounts. Much of this can
                be automated.
            </p>

            <Typography variant='subtitle2' id='costliness'>
                Costliness (Our New Tool)
            </Typography>
            <p>
                Utilizing the full power of Nostr+Lightning, there will also be
                an aspect of costliness that will allow for ongoing development
                and support of the NotABot community while incentivizing
                verifiers and disincentivizing would be spammers. I'm not sure
                that something like this would have even been possible before
                now. If ever there was a time to be bold and try new things,
                it's now.
            </p>

            <Typography
                variant='h6'
                fontWeight='fontWeightRegular'
                id='how-is-data-managed'
            >
                How is Data Managed?
            </Typography>
            <p>
                Nostr style. All data is posted to popular public relays and can
                be queried and used however someone wishes. I call it cowboy
                data. It's free to roam. The verification rules are enforced by
                the app itself. All verification notes are signed by the app's
                Nostr account <i>and</i> the verifier as evidence that the
                verification or rescission is legit.
            </p>

            <Typography
                variant='h6'
                fontWeight='fontWeightRegular'
                id='how-is-verified-human-structured'
            >
                How is NotABot Structured?
            </Typography>
            <p>
                My wish is to stand NotABot up as a community owned Co-Op. If
                there is a Nostr loving lawyer out there who thinks that NotABot
                stands a chance of working and would like to volunteer to help
                me set that up, please reach out.
            </p>

            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                sx={{ pt: '1rem', pb: '1rem' }}
            >
                <Divider sx={{ width: '66%' }} />
                <Typography
                    variant='h6'
                    fontWeight='fontWeightRegular'
                    sx={{ py: '1rem' }}
                >
                    Nostr n' Chill, Fam 🤙
                </Typography>
                <Typography sx={{ pb: '1rem' }}>
                    nostr:{' '}
                    <a
                        href='https://snort.social/p/npub1avar8zfzwswhq9xh3t0gc5l38pf5kesrkqe2s2ymrnymv3ddl2ps38t7kg'
                        target='_blank'
                        rel='noreferrer'
                        style={{
                            color: '#777',
                            // textDecoration: 'none',
                            textDecorationColor: 'lightgray',
                        }}
                    >
                        jason@paperlight.studio
                    </a>
                </Typography>
                <Typography>
                    email:{' '}
                    <a
                        href='mailto: hellopaperlight@gmail.com'
                        style={{
                            color: '#777',
                            // textDecoration: 'none',
                            textDecorationColor: 'lightgray',
                        }}
                    >
                        hellopaperlight@gmail.com
                    </a>
                </Typography>
            </Box>
        </>
    )
}

export default HowItWorksContent
