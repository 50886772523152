import { Box, Divider, Typography } from '@mui/material'
import { useMemo } from 'react'
import { VerificationSummary } from '../../app/types'
import { useTargetProfile } from '../../app/useTargetProfile'
import EmptyUserCardVertical from '../Cards/EmptyUserCardVertical'
import UserCardContainer from '../Cards/UserCardContainer'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const VerificationsList4 = () => {
    const {
        receivedList,
        receivedReciprocationsList,
        toList,
        isTargetAProgenitor,
    } = useTargetProfile()

    // console.log('VList 2: From List:', fromList)
    const limit = 5 - receivedList.length
    const missing: React.ReactNode[] = []
    const getMissing = () => {
        for (let i = 0; i < limit; i++) {
            missing.push(
                <Box key={`empty-user-card-v-${i}`}>
                    <EmptyUserCardVertical />
                </Box>
            )
        }
        return missing
    }

    const isReciprocal = (fromPubkey: string) => {
        return toList.find((to) => to.pubkey === fromPubkey) !== undefined
    }

    return (
        <>
            <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-evenly'
                alignItems='flex-start'
                flexWrap='wrap'
                gap={2}
            >
                {receivedList.map(
                    (from, i) => (
                        // i < 15 && (
                        <Box key={`from-${from.pubkey}`}>
                            <UserCardContainer
                                pubkey={from.pubkey}
                                isReciprocal={isReciprocal(from.pubkey)}
                            />
                        </Box>
                    )
                    // )
                )}
                {!isTargetAProgenitor() && <>{getMissing()}</>}
            </Box>

            {!!receivedReciprocationsList &&
                receivedReciprocationsList.length > 0 && (
                    <>
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            sx={{ mt: '1rem', mb: '.5rem' }}
                        >
                            <Divider sx={{ width: '100%' }}>
                                Reciprocations Received
                            </Divider>
                        </Box>

                        <Box
                            display='flex'
                            justifyContent='center'
                            gap={1}
                            sx={{ my: '1rem' }}
                        >
                            <Box sx={{ pt: '2px' }}>
                                <InfoOutlinedIcon
                                    sx={{
                                        mt: '-.5rem',
                                        color: '#777',
                                        borderRadius: '50%',
                                        width: '1.25rem',
                                        height: '1.25rem',
                                    }}
                                />
                            </Box>
                            <Typography
                                variant='body2'
                                sx={{ mb: '1rem', textAlign: 'center' }}
                            >
                                These don't count as Received Verifications
                            </Typography>
                        </Box>

                        <Box
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-evenly'
                            alignItems='flex-start'
                            flexWrap='wrap'
                            gap={2}
                        >
                            {receivedReciprocationsList.map((to, i) => (
                                <Box key={`to-${to.pubkey}`}>
                                    <UserCardContainer
                                        pubkey={to.pubkey}
                                        isReciprocal={isReciprocal(to.pubkey)}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
        </>
    )
}

export default VerificationsList4
