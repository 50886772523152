import { Box, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import AboutExtended from '../../components/Content/About/AboutExtended'

const HowItWorks = () => {
    return (
        <Box sx={{ mt: '1rem' }}>
            <NavLink
                to='/'
                style={{
                    paddingTop: '.5rem',
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                }}
            >
                <ChevronLeftIcon color='primary' style={{ fontSize: '1rem' }} />
                <Typography
                    component='span'
                    color='primary'
                    sx={{
                        fontSize: '1rem',
                    }}
                >
                    Home
                </Typography>{' '}
            </NavLink>
            <Box sx={{ p: '1rem', pt: '0' }}>
                <Typography
                    variant='h5'
                    // fontWeight='fontWeightRegular'
                    sx={{ pt: '1rem' }}
                >
                    About the App
                </Typography>

                <AboutExtended />
            </Box>
        </Box>
    )
}

export default HowItWorks
