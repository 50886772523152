export type ENV_VARS_INFO = {
    API_URL: string
}

export const DEV_ENV_VARS: ENV_VARS_INFO = {
    // API_URL: 'https://notabot-api.vercel.app/api/',
    API_URL: 'http://localhost:3001/api',
}

export const PROD_ENV_VARS: ENV_VARS_INFO = {
    API_URL: 'https://notabot-api.vercel.app/api',
}

export const getEnvVars = (): ENV_VARS_INFO => {
    if (process.env.NODE_ENV === 'development') {
        return DEV_ENV_VARS
    } else {
        return PROD_ENV_VARS
    }
}

export default getEnvVars
